@use 'partials/colors';
@use 'partials/fonts';

.default-button-skin {
    background-color: colors.$green-2;
    border-radius: 60px;
    height: 44px;
    width: 143px;
    color: colors.$skin;
    border: none;
    font-size: fonts.$btn-size;
    font-weight: fonts.$btn-weight;
    font-family: fonts.$normal-family;
    cursor: pointer;
}

.default-button {
    background-color: colors.$blue-3;
    border-radius: 60px;
    height: 44px;
    width: 187px;
    color: colors.$gray-1;
    border: none;
    font-size: fonts.$btn-size;
    font-weight: fonts.$btn-weight;
    font-family: fonts.$normal-family;
    text-transform: uppercase;
    cursor: pointer;
}

.default-button-outlined {
    background-color: colors.$white;
    border-radius: 60px;
    height: 44px;
    width: 143px;
    border: 1px solid colors.$blue-3;
    color: colors.$blue-3;
    font-size: fonts.$btn-size;
    font-weight: fonts.$btn-weight;
    font-family: fonts.$normal-family;
    text-transform: uppercase;
    cursor: pointer;
}

.default-button-outlined-white {
  border: 1px solid colors.$white;
  width: 187px;
  padding: 0;
  background-color: colors.$white;
  border-radius: 60px;
  height: 44px;
  color: colors.$blue-3;
  font-size: fonts.$btn-size;
  font-weight: fonts.$btn-weight;
  font-family: fonts.$normal-family;
  text-transform: uppercase;
  cursor: pointer;
}

.default-button-transparent {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 60px;
    height: 44px;
    width: 143px;
    border: 1px solid colors.$green-2;
    color: colors.$green-2;
    font-size: fonts.$btn-size;
    font-weight: fonts.$btn-weight;
    font-family: fonts.$normal-family;
    text-transform: uppercase;
    cursor: pointer;
}

.default-button-disabled {
    background-color: colors.$gray-2;
    border-radius: 60px;
    height: 44px;
    width: 143px;
    color: colors.$white;
    border: none;
    font-size: fonts.$btn-size;
    font-weight: fonts.$btn-weight;
    font-family: fonts.$normal-family;
}

.rounded-input {
    background: colors.$white;
    border: 1px solid colors.$black;
    border-radius: 60px;
    padding-left: 25px;
    padding-right: 25px;
    height: 45px;
    cursor: pointer;
}

.default-link {
    color: #828282;
    text-decoration: none;
}
