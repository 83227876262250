$blue-1:#C9EDE7;
$blue-2: #63D6CE;
$blue-3: #00b5af;
$green-1: #187A7A;
$green-2:#004851;
$purple-1: #ccd9ef;
$purple-2: #C2C4EF;
$purple-3: #352c5d;
$skin:#F5DEC9;
$pink: #f1a39d;
$coral: #ff815e;
$gray-1: #f2f2f2;
$gray-2: #b3b3b3;
$gray-3: #797979;
$gray-4: #505050;
$error-red: #dc3545;
$black: #212529;
$white: #ffffff;
