$navbar-desktop-height: 109px;
$navbar-mobile-height: 61px;

.main-container {
  margin-top: $navbar-desktop-height;
  display: inline-block;
  width: 100%;

  @media (max-width: 1180px) {
    margin-top: 0;
  }
}
