@use "functions";

$normal-family: 'DM Sans';
$normal-size: functions.rem(16);
$normal-weight: 400;

$title-family: 'Agrandir Variable';
$title-family-DM-Sans: 'DM Sans';
$title-weight-light: 336;
$title-weight-bold: 469;

$btn-size: functions.rem(16.5);
$btn-weight: 700;
