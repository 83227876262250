@use "/src/assets/scss/partials/fonts";
@use "/src/assets/scss/partials/colors";
@use "/src/assets/scss/partials/sizes";
@import "/src/assets/scss/default-theme.scss";


@font-face {
  font-family: Agrandir Variable;
  src: url(assets/fonts/Agrandir-Variable.ttf) format("truetype");
}

body {
  margin: 0;
  font-size: fonts.$normal-size;
  font-weight: fonts.$normal-weight;
  font-family: fonts.$normal-family !important;
  color: colors.$green-2;
}

p {
  white-space: pre-wrap;
  line-height: 24px;
}

h1,
h2,
h3,
h4 {
  font-family: fonts.$title-family !important;
  font-weight: fonts.$title-weight-bold;
}

.hide-desktop {
  display: none;
}

.hide-mobile {
  display: block;
}

@media only screen and (max-width: 1180px) {
  /* For mobile phones: */

  .hide-mobile {
    display: none;
  }

  .hide-desktop {
    display: block;
  }
}
